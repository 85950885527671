export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#C50000" },
        { className: "secondary-bg-colour", colour: "#191919" },
        { className: "tertiary-bg-colour", colour: "#EFEFEF" },
        { className: "inactive-bg-colour", colour: "#cccccc" },
        { className: "misc-bg-colour", colour: "#f0f0f0" },
    ],
    text: [
        { className: "primary-text", colour: "#ffffff" },
        { className: "secondary-text", colour: "#191919" },
        { className: "tertiary-text", colour: "#666666" },
        { className: "misc-text", colour: "#C50000" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#C50000" },
        { className: "secondary-border-colour", colour: "#191919" },
        { className: "primary-free-border-colour", colour: "#666666" },
    ],
};
